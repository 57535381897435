import { initTippy } from './tippy'
import { getCurrentWeatherCondition } from './weather'
import { generateImage, gradientPlaceholders, initHotSpots, videoEmbed } from './media'
import { copyToClipboard } from './clipboard'
import { followMastodonInit } from './mastodon'
import { initNavigation, initNavAnimation } from './navigation'
import { enableScrollSpy } from './scrollspy'
import { initTracking } from './tracking'

getCurrentWeatherCondition()
videoEmbed()
// initHotSpots()
copyToClipboard()
followMastodonInit()
initNavigation()
initTippy()

const buttonDownSpam = () => {
    const forms = document.querySelectorAll('form.newsletter')
    if (!forms) {
        return
    }

    forms.forEach((form) => {
        form.addEventListener('submit', (event) => {
            const email = form.querySelector('input[type="email"]') as HTMLInputElement

            if (!email) {
                return
            }

            if (email.value.includes('data-backup-store.com')) {
                console.log('🖕🏻')
                event.preventDefault()
            } else {
                window.open('https://buttondown.email/mauricerenck', 'popupwindow')
            }
        })
    })
}

const footnotes = document.querySelectorAll('.footnote-backref')
if (footnotes) {
    footnotes.forEach((footnote) => {
        footnote.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4"><path stroke-linecap="round" stroke-linejoin="round" d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244" /></svg>`
    })
}

const modalTriggers = document.querySelectorAll('.modalTrigger')
modalTriggers.forEach((trigger) => {
    trigger.addEventListener('click', (event) => {
        const modalId = trigger.getAttribute('data-modal-id')

        if (!modalId) {
            return
        }

        event.preventDefault()

        const modal = document.getElementById(modalId) as HTMLDialogElement

        if (!modal) {
            return
        }

        const closeButtons = modal.querySelectorAll('.close')

        closeButtons.forEach((closeButton) => {
            closeButton.addEventListener('click', () => {
                modal.close()
            })
        })

        modal.showModal()
    })
})

const contributionYearSelect = document.querySelector('select.contribution-year')

if (contributionYearSelect) {
    contributionYearSelect.addEventListener('change', (event) => {
        const year = (event.target as HTMLSelectElement).value
        const contributionGraph = document.querySelector(`.contribution-year-${year}`)
        const contributions = document.querySelectorAll('.contributions li')

        contributions.forEach((contribution) => {
            contribution.classList.remove('active')
        })

        contributionGraph?.classList.add('active')
    })
}

const initBookshelf = () => {
    const bookshelfSelect = document.querySelector('.bookshelf-nav select')

    if (bookshelfSelect) {
        bookshelfSelect.addEventListener('change', (event) => {
            const target = (event.target as HTMLSelectElement).value
            window.location.href = target
        })
    }
}

const animateAvatars = () => {
    const tiles = document.querySelectorAll('.fancy-tile')

    tiles.forEach((tile) => {
        tile.addEventListener('mousemove', (event) => {
            const halfW = tile.clientWidth / 2
            const halfH = tile.clientHeight / 2

            var coorX = halfW - (event.pageX - tile.offsetLeft)
            var coorY = halfH - (event.pageY - tile.offsetTop)

            var degX = (coorY / halfH) * 10 + 'deg' // max. degree = 10
            var degY = (coorX / halfW) * -10 + 'deg' // max. degree = 10

            tile.style.transform =
                'perspective( 600px ) translate3d( 0, -2px, 0 ) scale(1.05) rotateX(' + degX + ') rotateY(' + degY + ')'
        })

        tile.addEventListener('mouseout', () => {
            tile.style.transform = ''
        })
    })
}

window.onload = () => {
    generateImage()
    gradientPlaceholders()
    animateAvatars()
}

enableScrollSpy()
initBookshelf()
initTracking()
initNavAnimation()
buttonDownSpam()
