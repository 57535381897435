export const initHotSpots = () => {
    const spots: NodeListOf<Element> = document.querySelectorAll('.spot')
    const infoBox: any = document.querySelector('.spot-info')

    if (!infoBox) {
        return
    }

    spots.forEach((spot) => {
        spot.addEventListener('mouseenter', (event: any) => {
            const content = spot.getAttribute('data-info')
            infoBox.innerHTML = content

            infoBox.style.top = (event.pageY - 20 + 'px').toString()
            infoBox.style.left = (event.pageX + 20 + 'px').toString()
            infoBox.style.display = 'block'
            infoBox.classList.remove('hidden')
        })
        spot.addEventListener('mouseleave', (event: any) => {
            infoBox.classList.add('hidden')
        })
    })
}

export const videoEmbed = () => {
    const videos = document.querySelectorAll('.video')

    videos.forEach((video) => {
        const width = video.clientWidth
        const height = width * 0.5625

        const iframe: HTMLElement = video.querySelector('iframe')
        iframe.style.width = width + 'px'
        iframe.style.height = height + 'px'
    })
}

export const gradientPlaceholders = () => {
    var canvasList = document.querySelectorAll('canvas[data-colors]') as any

    if (!canvasList || canvasList.length === 0) {
        return
    }

    if (!canvasList.length) {
        const colors = canvasList.dataset.colors
        paintGradient(canvasList, colors)
        return
    }

    canvasList.forEach((canvas) => {
        const colors = canvas.dataset.colors

        if (!colors) {
            return
        }

        const colorArray = colors.split(',')

        if (colorArray.length === 0) {
            return
        }
        paintGradient(canvas, colors)
    })
}

const paintGradient = (canvas: HTMLCanvasElement, colors: string) => {
    var ctx = canvas.getContext('2d')

    if (!ctx) {
        return
    }

    const colorList = colors.split(',')

    // Create gradient
    const grd = ctx.createRadialGradient(0, 0, 0, 300, 200, 900)
    const rgbColor = hex2rgb(colorList[0])
    grd.addColorStop(0, `rgba(${rgbColor.r},${rgbColor.g},${rgbColor.b}, 0.8)`)
    grd.addColorStop(0.5, `rgba(${rgbColor.r},${rgbColor.g},${rgbColor.b}, 0.12)`)

    // Fill with gradient
    ctx.fillStyle = grd
    ctx.fillRect(0, 0, 600, 400)

    const grd2 = ctx.createRadialGradient(600, 0, 0, 300, 200, 900)
    const rgbColor2 = hex2rgb(colorList[1])

    grd2.addColorStop(0, `rgba(${rgbColor2.r},${rgbColor2.g},${rgbColor2.b}, 0.8)`)
    grd2.addColorStop(0.5, `rgba(${rgbColor2.r},${rgbColor2.g},${rgbColor2.b}, 0.12)`)

    // Fill with gradient
    ctx.fillStyle = grd2
    ctx.fillRect(0, 0, 600, 400)

    const grd3 = ctx.createRadialGradient(600, 400, 0, 300, 200, 900)
    const rgbColor3 = hex2rgb(colorList[2])

    grd3.addColorStop(0, `rgba(${rgbColor3.r},${rgbColor3.g},${rgbColor3.b}, 0.8)`)
    grd3.addColorStop(0.5, `rgba(${rgbColor3.r},${rgbColor3.g},${rgbColor3.b}, 0.12)`)

    // Fill with gradient
    ctx.fillStyle = grd3
    ctx.fillRect(0, 0, 600, 400)

    const grd4 = ctx.createRadialGradient(0, 400, 0, 300, 200, 800)
    const rgbColor4 = hex2rgb(colorList[3])
    grd4.addColorStop(0, `rgba(${rgbColor4.r},${rgbColor4.g},${rgbColor4.b}, 0.8)`)
    grd4.addColorStop(0.5, `rgba(${rgbColor4.r},${rgbColor4.g},${rgbColor4.b}, 0.12)`)

    // Fill with gradient
    ctx.fillStyle = grd4
    ctx.fillRect(0, 0, 600, 400)
}

const hex2rgb = (hex) => {
    const r = parseInt(hex.slice(1, 3), 16)
    const g = parseInt(hex.slice(3, 5), 16)
    const b = parseInt(hex.slice(5, 7), 16)

    // return {r, g, b}
    return { r, g, b }
}

export const generateImage = async () => {
    const canvasList: NodeListOf<HTMLCanvasElement> = document.querySelectorAll('.no-image-placeholder')

    canvasList.forEach((canvas) => {
        const title = canvas.dataset.text

        if (!title) {
            return
        }

        var ctx = canvas.getContext('2d')

        if (!ctx) {
            return
        }

        var grd = ctx.createLinearGradient(0, 0, canvas.width, canvas.height)
        grd.addColorStop(0, '#da7d02')
        grd.addColorStop(1, '#b75c56')

        ctx.fillStyle = grd
        ctx.fillRect(0, 0, canvas.width, canvas.height)

        ctx.font = '38px "ProximaNova", Arial'
        ctx.fillStyle = 'white'
        ctx.shadowOffsetX = 0
        ctx.shadowOffsetY = 0
        ctx.shadowBlur = 5
        ctx.textAlign = 'left'
        ctx.fillText(title, canvas.width / 2, canvas.height / 2)
        ctx.fillText(title, 0, 300)
        ctx.fillText(title, 40, 100)
        ctx.fillText(title, 120, 400)
    })
}
